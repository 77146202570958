





































.flex-row {
  display: flex;
  flex-flex-direction: row;
  padding: 3rem 0;
}
