














































@import '~variables';

.modal-mask {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 20%;
  width: 90%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-container {
  width: 40rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 2px;
}

.column__button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2rem;
}

.button {
  border: $border-medium solid $light;
  background-color: white;
  border-radius: 10px;
  color: $secondary;
  width: 6rem;
  text-align: center;
  cursor: pointer;
}
