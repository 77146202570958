@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.select {
  display: flex;
  justify-content: center;
}
.no-element {
  text-align: center;
  width: 100%;
  font-size: 1rem;
}
.select--input {
  width: 10%;
  height: 2rem;
  border-radius: 5px;
  background-color: #fff;
  padding-left: 1rem;
}
.dashboards {
  display: flex;
  flex-direction: column;
  padding: 0 5rem;
}
.dashboard-infos {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.title {
  font-weight: 700 !important;
}
.big {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}
.dashboard-scroll {
  display: flex;
  overflow-y: auto;
  justify-content: space-around;
}
.dashboard-margin {
  margin-bottom: 1rem;
}
.dashboard-heigth {
  height: 50rem;
}
.button--container {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}
.copy-button {
  border: 4px solid #f3f1ef;
  background-color: #fff;
  padding: 0.3rem 2rem;
  border-radius: 15px;
  color: #373737;
  width: 14rem;
  text-align: center;
  cursor: pointer;
  margin-top: 1rem;
}
.share-button {
  border: 4px solid #f3f1ef;
  background-color: #fff;
  padding: 0.3rem 2rem;
  border-radius: 15px;
  color: #373737;
  width: 15rem;
  text-align: center;
  cursor: pointer;
  margin-top: 1rem;
}
/*# sourceMappingURL=src/app/pages/admin/components/adminDashboard.css.map */