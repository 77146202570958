
















































@import '~variables';

.tabs {
  width: 75%;
  margin: 0 auto;
}
