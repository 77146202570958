@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.modal-mask {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 20%;
  width: 90%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
}
.modal-container {
  width: 40rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 2px;
}
.modal--body {
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bodyError {
  height: 7rem !important;
}
.users--select {
  display: flex;
  align-items: flex-start;
}
.column__button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 1.5rem;
}
.button {
  border: 2px solid #f3f1ef;
  background-color: #fff;
  border-radius: 10px;
  color: #373737;
  width: 6rem;
  text-align: center;
  cursor: pointer;
}
.error {
  color: #f00;
}
.select--input {
  margin-left: 0.3rem;
  width: 70%;
  height: 1.3rem;
  border-radius: 5px;
  background-color: #f3f1ef;
  padding-left: 1rem;
}
/*# sourceMappingURL=src/app/pages/admin/components/modalShare.css.map */