






























































































































@import '~variables';

.title {
  font-weight: $fw-bold !important;
}

.dashTier {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashWidget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0.5rem 0;
  border-radius: 10px;
  border: $border-thick solid $light;
  padding: 0.6rem 1rem;
  background-color: white;
  width: 22rem;
  height: 25rem;
}

.dashJsonButton {
  display: flex;
  justify-content: center;
}

.flexCol {
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
}

.button {
  border: $border-medium solid $light;
  background-color: $light;
  border-radius: 10px;
  color: $secondary;
  width: 10rem;
  text-align: center;
  cursor: pointer;
  margin-top: 1rem;
}
