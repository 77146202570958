









































































































































































@import '~variables';

.select {
  display: flex;
  justify-content: center;
}

.no-element {
  text-align: center;
  width: 100%;
  font-size: $fs-body;
}

.select--input {
  width: 10%;
  height: 2rem;
  border-radius: 5px;
  background-color: white;
  padding-left: 1rem;
}

.dashboards {
  display: flex;
  flex-direction: column;
  padding: 0 5rem;
}

.dashboard-infos {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.title {
  font-weight: $fw-bold !important;
}

.big {
  font-size: $fs-h3;
  margin-bottom: 1rem;
}

.dashboard-scroll {
  display: flex;
  overflow-y: auto;
  justify-content: space-around;
}

.dashboard-margin {
  margin-bottom: 1rem;
}

.dashboard-heigth {
  height: 50rem;
}

.button--container {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}

.copy-button {
  border: $border-thick solid $light;
  background-color: white;
  padding: 0.3rem 2rem;
  border-radius: 15px;
  color: $secondary;
  width: 14rem;
  text-align: center;
  cursor: pointer;
  margin-top: 1rem;
}

.share-button {
  border: $border-thick solid $light;
  background-color: white;
  padding: 0.3rem 2rem;
  border-radius: 15px;
  color: $secondary;
  width: 15rem;
  text-align: center;
  cursor: pointer;
  margin-top: 1rem;
}
