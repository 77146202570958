@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.title {
  font-weight: 700 !important;
}
.dashTier {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dashWidget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0.5rem 0;
  border-radius: 10px;
  border: 4px solid #f3f1ef;
  padding: 0.6rem 1rem;
  background-color: #fff;
  width: 22rem;
  height: 25rem;
}
.dashJsonButton {
  display: flex;
  justify-content: center;
}
.flexCol {
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
}
.button {
  border: 2px solid #f3f1ef;
  background-color: #f3f1ef;
  border-radius: 10px;
  color: #373737;
  width: 10rem;
  text-align: center;
  cursor: pointer;
  margin-top: 1rem;
}
/*# sourceMappingURL=src/app/pages/admin/components/widget.css.map */