@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.container--datas {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;
}
.table-data {
  width: 90%;
  border-collapse: collapse;
  margin-bottom: 0.5rem;
}
th {
  border-border-collapse: collapse;
  border: 2px solid #f3f1ef;
  padding: 0.5rem 0;
}
.data {
  font-weight: 400;
  width: 50%;
}
.pointer {
  cursor: pointer;
  color: #373737;
}
.tick {
  width: 1.3rem;
}
.cross {
  width: 2rem;
  margin-left: 1rem;
  cursor: pointer;
}
.isUnderline {
  cursor: pointer;
  text-decoration: underline;
}
.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.column--button {
  display: flex;
  width: 90%;
  justify-content: space-around;
  margin-bottom: 2rem;
}
.button {
  border: 2px solid #f3f1ef;
  background-color: #fff;
  border-radius: 10px;
  color: #373737;
  width: 6rem;
  text-align: center;
  cursor: pointer;
}
.button:active {
  background-color: #f3f1ef;
}
/*# sourceMappingURL=src/app/pages/admin/components/table.css.map */