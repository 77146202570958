@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.usersList {
  display: flex;
  flex-direction: column;
  width: 20%;
  border-right: 1px solid #f3f1ef;
}
.date {
  margin-left: 1rem;
}
.quasar--select {
  width: 100%;
  display: flex;
  justify-content: center;
}
.select--input {
  width: 80%;
  border-radius: 5px;
  background-color: #fff;
  padding-left: 1rem;
  margin-bottom: 2rem;
}
.userInfo {
  display: flex;
  flex-direction: column;
  height: 62rem;
  margin-left: 2rem;
  overflow-y: auto;
  font-size: 1rem;
}
.title {
  font-weight: 700;
}
.margin-line {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}
.user-groups-SSO {
  margin-bottom: 2rem;
}
.user-nodes {
  margin-bottom: 6rem;
}
.group-names {
  margin-top: 0.3rem;
}
.group-elem {
  list-style-type: disc;
  list-style-position: inside;
  margin-left: 1rem;
}
.group-node {
  margin-left: 6rem;
  display: list-item;
  list-style-type: circle;
  list-style-position: inside;
  padding: 0.3rem 0;
}
.path-node {
  margin-top: 0.5rem;
}
.path {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  margin-left: 1rem;
}
.node-uuid--container {
  margin: 1rem 0 2rem 0;
  display: flex;
  justify-content: center;
  width: 90%;
  border-radius: 4px;
}
.nodeUuid {
  font-size: 0.8rem;
  background-color: #464749;
  color: #fff;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  border-radius: 5px;
}
.clipboard {
  width: 5rem;
  padding: 0.5rem 0.3rem;
  cursor: pointer;
  background-color: #f4f4f4;
  text-align: center;
  border-radius: 5px;
  margin-left: -0.5rem;
}
/*# sourceMappingURL=src/app/pages/admin/components/usersList.css.map */