





















































































































@import '~variables';

.modal-mask {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 20%;
  width: 90%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-container {
  width: 40rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 2px;
}

.modal--body {
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bodyError {
  height: 7rem !important;
}

.users--select {
  display: flex;
  align-items: flex-start;
}

.column__button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 1.5rem;
}

.button {
  border: $border-medium solid $light;
  background-color: white;
  border-radius: 10px;
  color: $secondary;
  width: 6rem;
  text-align: center;
  cursor: pointer;
}

.error {
  color: red;
}

.select--input {
  margin-left: 0.3rem;
  width: 70%;
  height: 1.3rem;
  border-radius: 5px;
  background-color: $light;
  padding-left: 1rem;
}
