
























































































@import '~variables';

.container--datas {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;
}

.table-data {
  width: 90%;
  border-collapse: collapse;
  margin-bottom: 0.5rem;
}

th {
  border-border-collapse: collapse;
  border: $border-medium solid $light;
  padding: 0.5rem 0;
}

.data {
  font-weight: $fw-regular;
  width: 50%;
}

.pointer {
  cursor: pointer;
  color: $secondary;
}

.tick {
  width: 1.3rem;
}

.cross {
  width: 2rem;
  margin-left: 1rem;
  cursor: pointer;
}

.isUnderline {
  cursor: pointer;
  text-decoration: underline;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.column--button {
  display: flex;
  width: 90%;
  justify-content: space-around;
  margin-bottom: 2rem;
}

.button {
  border: $border-medium solid $light;
  background-color: white;
  border-radius: 10px;
  color: $secondary;
  width: 6rem;
  text-align: center;
  cursor: pointer;
}

.button:active {
  background-color: $light;
}
