



















































































































































































@import '~variables';

.usersList {
  display: flex;
  flex-direction: column;
  width: 20%;
  border-right: $border-light solid $light;
}

.date {
  margin-left: 1rem;
}

.quasar--select {
  width: 100%;
  display: flex;
  justify-content: center;
}

.select--input {
  width: 80%;
  border-radius: 5px;
  background-color: white;
  padding-left: 1rem;
  margin-bottom: 2rem;
}

.userInfo {
  display: flex;
  flex-direction: column;
  height: 62rem;
  margin-left: 2rem;
  overflow-y: auto;
  font-size: $fs-body;
}

.title {
  font-weight: $fw-bold;
}

.margin-line {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.user-groups-SSO {
  margin-bottom: 2rem;
}

.user-nodes {
  margin-bottom: 6rem;
}

.group-names {
  margin-top: 0.3rem;
}

.group-elem {
  list-style-type: disc;
  list-style-position: inside;
  margin-left: 1rem;
}

.group-node {
  margin-left: 6rem;
  display: list-item;
  list-style-type: circle;
  list-style-position: inside;
  padding: 0.3rem 0;
}

.path-node {
  margin-top: 0.5rem;
}

.path {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  margin-left: 1rem;
}

.node-uuid--container {
  margin: 1rem 0 2rem 0;
  display: flex;
  justify-content: center;
  width: 90%;
  border-radius: 4px;
}

.nodeUuid {
  font-size: $fs-little;
  background-color: $dark;
  color: white;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  border-radius: 5px;
}

.clipboard {
  width: 5rem;
  padding: 0.5rem 0.3rem;
  cursor: pointer;
  background-color: $dash-light;
  text-align: center;
  border-radius: 5px;
  margin-left: -0.5rem;
}
